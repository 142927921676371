import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import get from "lodash/get";
import IndexBlock from "../components/IndexBlock";
import PaginationBlock from "../components/PaginationBlockAtom";
import SEO from "../components/seo";

class ExhibitsIndex extends Component {
	render() {
		const posts = get(this, "props.data.recraft.entries");
		const { currentPage, numPages } = this.props.pageContext;
		const isFirst = currentPage === 1;
		const isLast = currentPage === numPages;
		const prevPage =
			currentPage - 1 === 1
				? "exhibits/"
				: "exhibits/" + (currentPage - 1).toString();
		const nextPage = "exhibits/" + (currentPage + 1).toString();
		const featuredPost = posts[0];
		return (
			<Layout>
				<SEO
					title="Exhibits"
					keywords={[`dumbo`, `places`, `dumbo businesses`]}
				/>
				<IndexBlock posts={posts} featuredPost={featuredPost} />

				<PaginationBlock
					nextPage={nextPage}
					prevPage={prevPage}
					numPages={numPages}
					currentPage={currentPage}
					isFirst={isFirst}
					isLast={isLast}
					linkWord={"exhibits"}
				/>
			</Layout>
		);
	}
}

export default ExhibitsIndex;

export const exhibitsListQuery = graphql`
	query exhibitsListQuery($offset: Int!, $limit: Int!) {
		recraft {
			entries(section: [exhibits], limit: $limit, offset: $offset) {
				... on Recraft_Exhibits {
					title
					uri
					slug
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					body {
						content
					}
					overview {
						content
					}
					postDate
					primaryContact {
						phone
					}
					addressSelector {
						... on Recraft_Addresses {
							streetNumber
							streetName
							streetType
							title
						}
					}
				}
			}
		}
	}
`;

// const posts = get(this, "props.data.craft.entries");

// orderBy: "title asc"
